import React, { Component } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Checkbox from '@mui/material/Checkbox';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usernameError: '',
      passwordError: '',
      passwordVisible: false,
      errorMessage: '',
      snackbarOpen: false,
      alertSeverity: 'error',
      checked: true,
    };
  }

  handleChange = (event) => {
    this.setState({ checked: event.target.checked });
  }

  handleLogin = async () => {
    const username = document.getElementById('username-login').value;
    const password = document.getElementById('password-login').value;

    if (username.length > 40 || password.length > 16) {
      if (username.length > 40) {
        this.setState({ usernameError: 'Username exceeds 40 characters' });
      } else {
        this.setState({ usernameError: '' });
      }

      if (password.length > 16) {
        this.setState({ passwordError: 'Password exceeds 16 characters' });
      } else {
        this.setState({ passwordError: '' });
      }
      return;
    }

    if (!this.state.checked) {
      this.throwError('Please agree to the User Agreement and Privacy Policy');
      return;
    }

    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/login`, {
        method: 'POST',
        body: formData,
      });
      // console.log(response);
      const data = await response.json();
      if (response.status === 200) {
        const now = new Date();
        const expire_time = new Date(now.getTime() + 1000 * 60 * 60 * 5.5);
        localStorage.setItem("token", data.token);
        localStorage.setItem("username", data.username);
        localStorage.setItem("expire_time", expire_time);
        this.throwError("Sign in Succeed! Redirects to the home page...", true);
        setTimeout(() => {
          window.location.href = '/';
        }, 1000);
      } else {
        this.throwError(data.message);
      }
    } catch (error) {
      console.log(error);
      this.throwError('Network ERROR!');
    }
  };

  handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ snackbarOpen: false });
  };

  throwError = (message, green = false) => {
    if (green) {
      this.setState({ alertSeverity: 'success' });
    } else {
      this.setState({ alertSeverity: 'error' });
    }
    this.setState({
      errorMessage: message,
      snackbarOpen: true,
    });
    setTimeout(() => {
      this.setState({ snackbarOpen: false });
    }, 3000);
    return;
  }

  render() {
    return (
      <Box sx={{
        height: 'calc(100vh - 64px)',
        position: 'relative',
        width: '100vw',
        transition: 'all 0.3s',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
        component="div">
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          border: '1px solid black',
          borderRadius: '10px',
          backgroundColor: '#fff',
          padding: '60px 100px 80px 100px'
        }}
          component="div"
        >
          <h1>Sign In</h1>
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}>
            <TextField
              required
              error={this.state.usernameError ? true : false}
              id="username-login"
              label="Username or Email"
              variant="outlined"
              helperText={this.state.usernameError ? this.state.usernameError : 'Please enter your username or email'}
              onFocus={() => this.setState({ usernameError: '' })}
              sx={{
                marginBottom: '20px',
                width: '250px'
              }}
              inputProps={{
                maxLength: 40
              }}
            />
            <TextField
              required
              error={this.state.passwordError ? true : false}
              id="password-login"
              label="Password"
              variant="outlined"
              helperText={this.state.passwordError ? this.state.passwordError : 'Please enter your password'}
              onFocus={() => this.setState({ passwordError: '' })}
              sx={{
                marginBottom: '20px',
                width: '250px',
                position: 'relative'
              }}
              InputProps={{
                type: this.state.passwordVisible ? 'text' : 'password',
                endAdornment: (
                  <IconButton
                    onClick={() => this.setState({ passwordVisible: !this.state.passwordVisible })}
                    sx={{ float: 'right' }}
                  >
                    {this.state.passwordVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                ),
                maxLength: 16,
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  this.handleLogin();
                }
              }}
            />
            <Button variant="contained" onClick={this.handleLogin} sx={{ width: '200px', marginBottom: '20px' }}>Sign In</Button>
            <Button variant="text" onClick={() => { window.location.href = "/register"; }} size='small'>sign up</Button>
            <Button variant="text" onClick={() => { window.location.href = "/reset"; }} size='small'>reset password</Button>
            {/* 同意用户协议与隐私政策 */}
            <Box sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
              marginTop: '20px',

            }}>
              <Checkbox
                checked={this.state.checked}
                onClick={(event) => this.handleChange(event)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <label htmlFor="agreement">
                Agree to the
                <a href="/agreement" target="_blank" style={{ color: '#3874cb', textDecoration: 'none' }}> User Agreement </a>
                and
                <a href="/privacy" target="_blank" style={{ color: '#3874cb', textDecoration: 'none' }}> Privacy Policy </a>
              </label>
            </Box>
          </Box>
        </Box>

        <Snackbar open={this.state.snackbarOpen} autoHideDuration={6000} onClose={this.handleCloseSnackbar} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
          <Alert onClose={this.handleCloseSnackbar} severity={this.state.alertSeverity} sx={{ width: '100%' }}>
            {this.state.errorMessage}
          </Alert>
        </Snackbar>

      </Box>
    )
  }
}
