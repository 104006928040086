// import { colors } from '@mui/material';
import Box from '@mui/material/Box';
import Markdown from 'react-markdown';
import { Divider } from '@mui/material';
// import Docicon from './doc.svg';
import React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Button from '@mui/material/Button';
import './Docs.css';

const markdown = `
# User Manual

## Introduction

This manual provides step-by-step instructions for creating a task or checking task results on the SHTech Fold AI Platform to predict protein structures or redesign protein sequence. Whether you are inputting single sequences or complex protein assemblies, these steps will guide you through using the web interface efficiently.

## Accessing the Task Creation Page

1. **Login**: Sign into your account on the SHTech Fold AI platform.
2. **Navigate to Task Creation**: From the main dashboard or menu, click on the “Create a Task” option.

## AlphaFold Task Details

Once you are on the AlphaFold task creation page, you will see a form to enter the details of your new task. Follow these steps to complete the form:

1. **Task Name**:
- **Description**: Enter a unique name to identify your task. The name will be used to show tasks in the task dashboard.
- **Field**: Task Name
- **Example**: "Protein Prediction - Date/Project Name"

2. **Model Selection**:
- **Description**: Choose the appropriate model for your protein structure prediction.
- **Field**: model
- **Options**:
    - monomer - For single chain protein predictions. This option will utilize the original model used at CASP14 with no ensembling.
    - monomer_ptm - For single chain protein predictions with additional pTM(predicted TM-score) head. This option will utilize the original CASP14 model fine tuned with the pTM head, providing a pairwise confidence measure. However, it is slightly less accurate than the normal monomer model.
    - multimer - For single/multiple chain(s) protein predictions. This option will utilize the AlphaFold-Multimer model. And this is the only model that can predict complex structure or protein structures with multiple chains.
    - monomer_single - 蛋白质单链预测，使用与上方monomer相同的模型的同时不使用MSA搜库结果以及template搜库结果
    - monomer_single_ptm - 蛋白质单链预测，使用与上方monomer_ptm相同的模型进行预测。不使用MSA搜库结果以及template搜库结果
- **Note**:
    - All models will provide a per residue predicted Local Distance Difference Test plot in the report.
    - The model monomer_ptm and multimer will additionally provide a Predicted Alignment Error plot in the report.
    - An additional MSA coverage plot will be provided if the monomer or monomer_ptm or multimer database is selected.

3. **Database Selection**:
- **Description**: Select the database configuration to be used.
- **Field**: database
- **Options**:
    - full - Employs the entire dataset available for Multiple Sequence Alignment (MSA) searching, ensuring comprehensive data utilization.
    - reduced - Utilizes a subset of the available data for MSA searching. Selecting this option may reduce inference time; however, it could also result in diminished model performance.
    - no_dbs - Does not utilize any available data for MSA searching. This option omits the MSA searching step entirely, which may significantly decrease inference time but could adversely affect the model's performance.

4. **Max Template Date**:
- **Description**: Specify the latest date for template data inclusion.
- **Field**: max_template_date
- **Example**: Specify a recent date to incorporate the templates from the Protein Data Bank (PDB) database. Should you wish to exclude certain protein structures from the PDB database to prevent the model from leveraging these specific structures in its predictions, configure the max_template_date option to a date preceding the release of the targeted structures in the PDB database.

5. **Uploading Sequence Data**
- You have two options for uploading your sequence data:
    - **Upload Files**:
        - **Description**: Upload your protein sequences in FASTA format.
        - **Button**: UPLOAD FILE(S)
        - **Supported Formats**: .fasta, .fa
        - **Instructions**: Click the button, navigate to your files, select them, and confirm the upload.

    - **Enter Sequence Manually**:
        - **Description**: Directly input your sequence data into the platform.
        - **Field**: Sequence Content
        - **Instructions**:
            - Enter your sequence in the provided text area.
            - Sequence Name: Provide a name for your sequence.
            - Click ADD SEQUENCE to add more sequences if needed.

6. **Finalizing Your Task**
- **Option to Split Sequences**: If uploading a FASTA file with multiple sequences, check the box if you wish to treat them as separate tasks.
- **Create Task**: Review all the information, ensure that all fields are correctly filled, and click the CREATE button to submit your task for processing.
- Once submitted, the task will be processed according to the specified parameters. You can track the progress or view results from the task dashboard.
`;

export default function Docs() {
    const [userManualOpen, setUserManualOpen] = React.useState(true);

    React.useEffect(() => {
        const h2Elements = document.querySelectorAll('h2');
        const navBar = document.getElementById('navbar'); // 假设你的导航栏有一个ID为'navbar'

        if (navBar.childElementCount === 0) {

            h2Elements.forEach((h2, index) => {
                const id = `section-${index}`;
                h2.id = id;

                const navLink = document.createElement('a');
                navLink.href = `#${id}`;
                navLink.textContent = h2.textContent; // 使用h2的文本作为链接文本
                navLink.className = 'nav-link'; // 可选：为链接添加类名，以便于样式化

                // 将链接添加到导航栏
                navBar.appendChild(navLink);
            });
        }
    }, []);

    React.useEffect(() => {
        const handleScroll = () => {
            const h2Elements = document.querySelectorAll('h2');
            const navLinks = document.querySelectorAll('.nav-link');

            let currentId = '';
            let closestDistance = Infinity;

            h2Elements.forEach((h2) => {
                const rect = h2.getBoundingClientRect();
                const distance = Math.abs(rect.top);
                if (distance < closestDistance) {
                    closestDistance = distance;
                    currentId = h2.id;
                }
            });

            navLinks.forEach((navLink) => {
                navLink.classList.remove('active');
                if (navLink.getAttribute('href') === `#${currentId}`) {
                    navLink.classList.add('active');
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);


    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            fontFamily: 'SF Pro Text',
            lineHeight: '1.8',
            color: '#465a69'
        }}>
            <Box sx={{ position: 'fixed', top: '64px', left: '0' }}>
                <Box sx={{
                    backgroundColor: '#ffffff', width: '350px', display: 'flex', justifyContent: 'center', padding: '30px', height: 'calc(100vh - 64px)'
                }}>
                    <Box sx={{ textAlign: 'left' }}>
                        <Box
                            sx={{
                                fontFamily: 'SF Pro Text',
                                fontSize: '20.86px',
                                fontWeight: '600',
                                lineHeight: '25.03px',
                                color: '#A6A6A6',
                                marginBottom: '20px'
                            }}
                        >
                            GET STARTED
                        </Box>
                        <Button sx={{ fontSize: '20.86px', lineHeight: '27.05px', display: 'flex', flexDirection: 'row', alignItems: 'center', fontWeight: '600', color: '#000000' }}
                            startIcon={userManualOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                            onClick={() => { setUserManualOpen(!userManualOpen) }}
                        >
                            User Manual
                        </Button>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            margin: '0px 0 0 5px',
                            height: userManualOpen ? 'auto' : '0',
                            overflow: 'hidden',
                        }}>
                            {/* <Divider orientation="vertical" flexItem sx={{ borderRightWidth: 'thick' }} /> */}
                            <Box sx={{ padding: '10px', position: 'relative' }} id='navbar'>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box sx={{ backgroundColor: '#fff', width: 'calc(100vw - 350px)', padding: '20px', display: 'flex', justifyContent: 'center', marginLeft: '350px' }}>

                <Box sx={{ maxWidth: '1400px' }}>
                    <Markdown>
                        {markdown}
                    </Markdown>
                </Box>
            </Box>
        </Box >
    );
}