import React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import moment from 'moment';
import DownloadIcon from '@mui/icons-material/Download';
// import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import ErrorIcon from '@mui/icons-material/Error';
// import WarningIcon from '@mui/icons-material/Warning';
// import NextStepIcon from './scaleProtein.svg';
import ArticleIcon from '@mui/icons-material/Article';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { tooltipClasses } from '@mui/material/Tooltip';
import LinearProgress from '@mui/material/LinearProgress';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Button } from '@mui/material';


const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));


function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate"
                    sx={{
                        height: '10px', backgroundColor: '#F5F5F5', borderRadius: '5px',
                        '& .MuiLinearProgress-bar': {
                            // backgroundColor: props.value === 100 ? '#3FC375' : props.value === 0 ? '#FFA800' : '#FFA800',
                            backgroundColor: '#0779FF',
                            borderRadius: '5px',
                        }
                    }}
                    {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};




function Dot({ color }) {
    return (
        <Box
            sx={{
                width: '12px',
                height: '12px',
                borderRadius: '50%',
                backgroundColor: color,
                display: 'inline-block',
            }}
        />
    )
}


function Row({
    task_name,
    row,
    setLoading,
    setProgress,
    get_tasks,
    setReportData,
    setReportOpen,
    setLigandNextOpen,
    setLiFileName,
    setLiFileId
}) {
    const [open, setOpen] = React.useState(false);
    const [num_task, setNumTask] = React.useState(1);
    const [done_task, setDoneTask] = React.useState(0);
    const [failed, setFailed] = React.useState(0);
    const [pending, setPending] = React.useState(0);
    const [running, setRunning] = React.useState(0);
    const [length, setLength] = React.useState(0);

    function viewPDB(pdbID) {
        // console.log('/viewer?' + pdbID);
        window.location.href = '/viewer?' + pdbID;
    }

    React.useEffect(() => {
        let num = 0;
        let done = 0;
        let fail = 0;
        let running = 0;
        let pending = 0;
        let _length = 0;
        for (let key in row["subtasks"]) {
            num += 1;
            if (row["subtasks"][key]["status"] === 0) {
                pending += 1;
            } else if (row["subtasks"][key]["status"] === 1) {
                running += 1;
            } else if (row["subtasks"][key]["status"] === 2) {
                done += 1;
            } else if (row["subtasks"][key]["status"] === 3) {
                fail += 1;
            }
            _length += row["subtasks"][key]['sequence_length']
        }
        setNumTask(num);
        setDoneTask(done);
        setFailed(fail);
        setPending(pending);
        setRunning(running);
        setLength(_length);
    }, [row]);

    async function downSubTask(task_id, file_name) {
        const message = new FormData();
        message.append('task_id', task_id);
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/download_subtask`, {
                method: 'POST',
                body: message,
                headers: {
                    'Authorization': localStorage.getItem('token'),
                }
            });

            if (response.status === 200) {
                const reader = response.body.getReader();
                const contentLength = +response.headers.get('Content-Length');
                let receivedLength = 0; // 接收到的字节数
                let chunks = []; // 用来存储数据块的数组

                while (true) {
                    const { done, value } = await reader.read();

                    if (done) {
                        break;
                    }
                    chunks.push(value);
                    receivedLength += value.length;
                    setProgress(receivedLength / contentLength * 100);
                }

                setLoading(false);
                setProgress(0);
                const blob = new Blob(chunks);
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = `${file_name}.zip`;
                link.click();
            } else if (response.status === 401) {
                localStorage.clear();
                window.location.href = '/login';
            } else {
                console.log('Download failed:', response.statusText);
            }
        } catch (error) {
            console.log(error);
        }
    }

    async function deleteSubTask(task_id, file_name) {
        console.log('delete subtask', task_id);
        const message = new FormData();
        message.append('task_id', task_id);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/delete_task`, {
                method: 'POST',
                body: message,
                headers: {
                    'Authorization': localStorage.getItem('token'),
                }
            });
            const data = await response.json();
            if (response.status === 200) {
                get_tasks();
            } else if (response.status === 401) {
                localStorage.clear();
                window.location.href = '/login';
            } else {
                console.log(data.message);
            }
        } catch (error) {
            console.log(error);
        }
    }

    async function deleteTask(row) {
        for (let key in row["subtasks"]) {
            deleteSubTask(row["subtasks"][key]["subtask_id"], row["subtasks"][key]["file_name"])
        }
    }

    async function downTask(task_name) {
        const message = new FormData();
        message.append('task_name', task_name);
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/download_task`, {
                method: 'POST',
                body: message,
                headers: {
                    'Authorization': localStorage.getItem('token'),
                }
            });

            if (response.status === 200) {
                const reader = response.body.getReader();
                const contentLength = +response.headers.get('Content-Length');
                let receivedLength = 0; // 接收到的字节数
                let chunks = []; // 用来存储数据块的数组

                while (true) {
                    const { done, value } = await reader.read();

                    if (done) {
                        break;
                    }
                    chunks.push(value);
                    receivedLength += value.length;
                    setProgress(receivedLength / contentLength * 100);
                }

                setLoading(false);
                setProgress(0);
                const blob = new Blob(chunks);
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = `${task_name}.zip`;
                link.click();
            } else if (response.status === 401) {
                localStorage.clear();
                window.location.href = '/login';
            } else {
                console.log('Download failed:', response.statusText);
            }
        } catch (error) {
            console.log('Error:', error);
        }
    }

    async function reportSubTask(task_id, file_name, time, row) {
        let cacheTaskReport = localStorage.getItem('cacheTaskReport');
        cacheTaskReport = cacheTaskReport ? JSON.parse(cacheTaskReport) : {};
        // console.log(row)
        if (!(task_id in cacheTaskReport)) {
            const message = new FormData();
            message.append('task_id', task_id);
            setLoading(true);
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/get_report_plots`, {
                    method: 'POST',
                    body: message,
                    headers: {
                        'Authorization': localStorage.getItem('token'),
                    }
                });
                if (response.status === 200) {
                    const data = await response.json();
                    setReportData({
                        'task_id': task_id,
                        'filename': file_name,
                        'time': time,
                        'row': row,
                        'data': data
                    });
                    // console.log(data)
                    setReportOpen(true);
                    setLoading(false);
                    cacheTaskReport[task_id] = {
                        'task_id': task_id,
                        'filename': file_name,
                        'time': time,
                        'row': row,
                        'data': data
                    };
                    localStorage.setItem('cacheTaskReport', JSON.stringify(cacheTaskReport));
                } else if (response.status === 401) {
                    localStorage.clear();
                    window.location.href = '/login';
                } else {
                    console.log('Download failed:', response.statusText);
                }
            } catch (error) {
                console.log('Error:', error);
            }
        } else {
            let data = cacheTaskReport[task_id];
            setReportData({
                'task_id': data['task_id'],
                'filename': data['filename'],
                'time': data['time'],
                'row': row,
                'data': data['data']
            });
            setReportOpen(true);
        }
    }

    function CircularProgressWithLabel(props) {
        return (
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress variant="determinate" {...props} />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography variant="caption" component="div" color="text.secondary">
                        {`${Math.round(props.value)}%`}
                    </Typography>
                </Box>
            </Box>
        );
    }

    CircularProgressWithLabel.propTypes = {
        value: PropTypes.number.isRequired,
    };

    return (
        <Box sx={{
            width: window.innerWidth > 1500 ? '1450px' : '100%'
        }}
        >
            <Box onClick={() => setOpen(!open)} id={task_name} sx={{
                position: 'relative',
                margin: '10px 0',
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    bottom: 0,
                    width: '5px',
                    backgroundColor: '#517DFF',
                }
            }}>
                <Box sx={{
                    display: 'flex',
                    backgroundColor: '#FAFAFA',
                    width: '100%',
                    // margin: '0 10px',
                    border: '1px solid #D6D6D6',
                }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <Box component={'div'} sx={{ padding: '10px', paddingBottom: '0' }} className='task-name-font'>
                            {
                                task_name.length > 20 ?
                                    <LightTooltip title={task_name}>
                                        <Box>{task_name.substring(0, 18)}...</Box>
                                    </LightTooltip>
                                    :
                                    <Box>{task_name}</Box>
                            }
                        </Box>

                        <Box component={'div'} sx={{ padding: '5px 10px', display: 'flex', flexDirection: window.innerWidth < 800 ? 'column' : 'row', alignContent: 'center', alignItems: window.innerWidth < 800 ? 'left' : 'center' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center', marginRight: '20px', width: '230px' }} className='task-small-font'>
                                <AccessTimeIcon fontSize='18px' /> {moment.unix(row.start_time).format('YYYY-MM-DD HH:mm:ss')}
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center', marginRight: '20px', width: '130px' }} className='task-small-font'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                    <circle cx="5" cy="5" r="5" fill="#517DFF" />
                                </svg>
                                <Box sx={{ marginLeft: '10px' }}>
                                    {row["type"]}

                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center', marginRight: '20px' }} className='task-small-font'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                    <circle cx="5" cy="5" r="5" fill="#517DFF" />
                                </svg>
                                <Box sx={{ marginLeft: '10px' }}>
                                    Length: {length}
                                </Box>
                            </Box>
                        </Box>

                        <Box sx={{ padding: '10px', display: 'flex', backgroundColor: '#fff', width: '100%', justifyContent: 'space-between' }}>
                            <Box component={'div'} sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center', marginRight: '20px', fontFamily: 'Inter', fontSize: '19.554px', fontStyle: 'normal', fontWeight: '500', lineHeight: '129.639%', width: '120px' }} >
                                    <Dot
                                        color={done_task === num_task ? '#3FC375'   // green
                                            : running > 0 ? '#FFA800'               // yellow
                                                : pending > 0 ? '#989898'               // grey
                                                    : failed > 0 ? '#C92B26'
                                                        : '#989898'
                                        }
                                    />
                                    <Box component={'span'} sx={{ marginLeft: '10px' }} color={
                                        done_task === num_task ? '#3FC375'   // green
                                            : running > 0 ? '#FFA800'               // yellow
                                                : pending > 0 ? '#989898'               // grey
                                                    : failed > 0 ? '#C92B26'
                                                        : '#989898'
                                    }>
                                        {
                                            done_task === num_task ? 'Finished' :
                                                running > 0 ? 'Processing' :
                                                    pending > 0 ? 'Pending' :
                                                        failed > 0 ? 'Failed' : 'Unknown'
                                        }
                                    </Box>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center', marginRight: '20px', width: '200px' }} className='task-small-font'>
                                    <LinearProgressWithLabel value={(done_task + failed) / num_task * 100} />
                                </Box>
                            </Box>
                            {
                                window.innerWidth < 800 ? null :
                                    <Box sx={{ fontSize: '18px', fontWeight: '450', textAlign: 'center', color: '#4766C3' }}>
                                        <LightTooltip title={'Download task'}>
                                            <span>
                                                <IconButton
                                                    aria-label="download"
                                                    sx={{ color: '#4766C3' }}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        downTask(task_name);
                                                    }}
                                                    disabled={(done_task > 0 && done_task + failed === num_task) ? false : true}
                                                >
                                                    <DownloadIcon />
                                                </IconButton>
                                            </span>
                                        </LightTooltip>
                                        <LightTooltip title={'Delete task'}>
                                            <span>
                                                <IconButton
                                                    aria-label="delete"
                                                    sx={{ color: '#4766C3' }}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        deleteTask(row);
                                                    }}
                                                    disabled={(done_task + failed === num_task) ? false : true}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </span>
                                        </LightTooltip>
                                    </Box>
                            }
                        </Box>

                    </Box>
                </Box>
            </Box>




            <Collapse in={open} timeout="auto" unmountOnExit>
                {Object.keys(row["subtasks"]).map((key) => (
                    <Box onClick={() => setOpen(!open)} id={task_name} sx={{
                        position: 'relative',
                        margin: '10px 0',
                    }}>
                        <Box sx={{
                            display: 'flex',
                            backgroundColor: '#FAFAFA',
                            width: '100%',
                            // margin: '0 10px',
                            border: '1px solid #D6D6D6',
                        }}
                        >
                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                <Box component={'div'} sx={{ padding: '10px', paddingBottom: '0', width: '100%', display: 'flex', alignContent: 'center', justifyContent: 'space-between', alignItems: 'center' }} className='task-name-font'>
                                    <Box>
                                        {
                                            row["subtasks"][key]["file_name"].length > 18 ?
                                                <LightTooltip title={row["subtasks"][key]["file_name"]}>
                                                    <div>{row["subtasks"][key]["file_name"].substring(0, 18)}...</div>
                                                </LightTooltip>
                                                :
                                                row["subtasks"][key]["file_name"]
                                        }
                                    </Box>
                                    {
                                        window.innerWidth < 800 ? null :
                                            <Box>
                                                <Button sx={{ backgroundColor: '#517DFF' }} onClick={() => {
                                                    if (row["type"] === 'ligandmpnn') {
                                                        setLiFileName(row["subtasks"][key]["file_name"])
                                                        setLiFileId(row["subtasks"][key]["subtask_id"])
                                                        setLigandNextOpen(true)
                                                    } else {
                                                        viewPDB(row["subtasks"][key]["subtask_id"])
                                                    }
                                                }}
                                                    variant='contained'
                                                    disabled={row["subtasks"][key]["status"] === 2 ? false : true}
                                                >
                                                    View Prediction
                                                </Button>
                                            </Box>
                                    }
                                </Box>

                                <Box component={'div'} sx={{ padding: '5px 10px', display: 'flex', flexDirection: window.innerWidth < 800 ? 'column' : 'row', alignContent: 'center', alignItems: window.innerWidth < 800 ? 'left' : 'center' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center', marginRight: '20px', width: '230px' }} className='task-small-font'>
                                        <AccessTimeIcon fontSize='18px' /> {moment.unix(row["subtasks"][key]["create_time"]).format('YYYY-MM-DD HH:mm:ss')}
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center', marginRight: '20px', width: '130px' }} className='task-small-font'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                            <circle cx="5" cy="5" r="5" fill="#517DFF" />
                                        </svg>
                                        <Box sx={{ marginLeft: '10px' }}>
                                            {row["type"]}

                                        </Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center', marginRight: '20px' }} className='task-small-font'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                            <circle cx="5" cy="5" r="5" fill="#517DFF" />
                                        </svg>
                                        <Box sx={{ marginLeft: '10px' }}>
                                            Length: {row["subtasks"][key]["sequence_length"]}
                                        </Box>
                                    </Box>
                                </Box>

                                <Box sx={{ padding: '10px', display: 'flex', backgroundColor: '#fff', width: '100%', justifyContent: 'space-between' }}>
                                    <Box component={'div'} sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center', marginRight: '20px', fontFamily: 'Inter', fontSize: '19.554px', fontStyle: 'normal', fontWeight: '500', lineHeight: '129.639%', width: '120px' }} >
                                            <Dot
                                                color={row["subtasks"][key]["status"] === 2 ? '#3FC375'
                                                    : row["subtasks"][key]["status"] === 3 ? '#C92B26'
                                                        : row["subtasks"][key]["status"] === 0 ? '#989898'
                                                            : '#FFA800'
                                                }
                                            />
                                            <Box component={'span'} sx={{ marginLeft: '10px' }}
                                                color={row["subtasks"][key]["status"] === 2 ? '#3FC375'
                                                    : row["subtasks"][key]["status"] === 3 ? '#C92B26'
                                                        : row["subtasks"][key]["status"] === 0 ? '#989898'
                                                            : '#FFA800'
                                                }
                                            >
                                                {
                                                    row["subtasks"][key]["status"] === 2 ? 'Finished'
                                                        : row["subtasks"][key]["status"] === 3 ? 'Failed'
                                                            : row["subtasks"][key]["status"] === 0 ? 'Pending'
                                                                : 'Processing'
                                                }
                                            </Box>
                                        </Box>
                                    </Box>

                                    {
                                        window.innerWidth < 800 ? null :
                                            <Box sx={{ fontSize: '18px', fontWeight: '450', textAlign: 'center', color: '#4766C3' }}>
                                                <LightTooltip title={'Download subtask'}>
                                                    <span>
                                                        <IconButton
                                                            aria-label="download"
                                                            sx={{ color: '#4766C3' }}
                                                            onClick={() => downSubTask(row["subtasks"][key]["subtask_id"], row["subtasks"][key]["file_name"])}
                                                            disabled={row["subtasks"][key]["status"] === 2 ? false : true}
                                                        >
                                                            <DownloadIcon />
                                                        </IconButton>
                                                    </span>
                                                </LightTooltip>
                                                {
                                                    row["type"] === 'ligandmpnn' ?
                                                        <></>
                                                        :
                                                        <LightTooltip title={'View subtask report'}>
                                                            <span>
                                                                <IconButton
                                                                    aria-label="report"
                                                                    sx={{ color: '#4766C3' }}
                                                                    onClick={() => reportSubTask(row["subtasks"][key]["subtask_id"], row["subtasks"][key]["file_name"], moment.unix(row["subtasks"][key]["create_time"]).format('YYYY-MM-DD HH:mm:ss'), row)}
                                                                    disabled={row["type"] === 'stfold' ? true : row["subtasks"][key]["status"] === 2 ? false : true}
                                                                >
                                                                    <ArticleIcon />
                                                                </IconButton>
                                                            </span>
                                                        </LightTooltip>
                                                }
                                                <LightTooltip title={'Delete subtask'}>
                                                    <span>
                                                        <IconButton
                                                            aria-label="delete"
                                                            sx={{ color: '#4766C3' }}
                                                            onClick={() => deleteSubTask(row["subtasks"][key]["subtask_id"], row["subtasks"][key]["file_name"])}
                                                            disabled={
                                                                (
                                                                    row["subtasks"][key]["status"] === 2 ||
                                                                    row["subtasks"][key]["status"] === 3 ||
                                                                    row["subtasks"][key]["status"] === 0
                                                                )
                                                                    ? false : true
                                                            }
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </span>
                                                </LightTooltip>
                                            </Box>
                                    }
                                </Box>

                            </Box>
                        </Box>
                    </Box>
                ))}
            </Collapse>
        </Box>
    );
}

export default Row;