import { Box } from "@mui/material";
// import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import React from "react";
import Divider from '@mui/material/Divider';
// import moment from "moment";
import finished_icon from './img/finished.svg';
import processing_icon from './img/processing.svg';
import failed_icon from './img/failed.svg';
import capacity_icon from './img/capacity.svg';
import pending from './img/pending.svg';


function jobStatus(status, progress) {
    return (
        <Box
            sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '180px', margin: '30px 0 20px 0', fontFamily: 'Inter', fontSize: '18px', fontWeight: '500', lineHeight: '23.4px', }}
        >
            <Box
                sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'center', margin: '0 0 10px 0' }}
            >
                <Box component={'span'}
                    sx={{ color: '#000000', fontSize: '26px', fontWeight: '700', margin: '0 8px 0 0' }}
                >
                    {progress}
                </Box>
                <Box component={'span'}
                    sx={{ fontSize: '15.48px', fontWeight: '400', color: '#A1A1A1' }}
                >
                    {status === 'capacity' ? '%' : 'tasks'}
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'center', margin: '0 0 20px 0', fontSize: '17.92px',
                    color: status === 'finished' ? '#6AC064' : status === 'processing' ? '#E49808' : status === 'failed' ? '#C92B26' : '#A1A1A1',
                }}
            >
                <img src={status === 'finished' ? finished_icon : status === 'processing' ? processing_icon : status === 'failed' ? failed_icon : status === 'pending' ? pending : capacity_icon} alt="finished" style={{ width: '27.7px', height: '27.7px', margin: '0 10px 0 0' }} />
                {status === 'finished' ? 'Finished' : status === 'processing' ? 'Processing' : status === 'failed' ? 'Failed' :  status === 'pending' ? 'Pending' : 'Capacity'}
            </Box>
        </Box>
    )
}

function TitleInfo({ data }) {
    const [finished, setFinished] = React.useState(0);
    const [processing, setProcessing] = React.useState(0);
    const [failed, setFailed] = React.useState(0);
    // eslint-disable-next-line
    const [waiting, setWaiting] = React.useState(0);
    // eslint-disable-next-line
    const [residue, setResidue] = React.useState(0);
    // eslint-disable-next-line
    const [storage, setStorage] = React.useState(1);
    // eslint-disable-next-line
    const [used, setUsed] = React.useState(0);
    // eslint-disable-next-line
    const [updateTime, setUpdateTime] = React.useState(0);


    React.useEffect(() => {
        let _finished = 0;
        let _processing = 0;
        let _waiting = 0;
        let _failed = 0;
        for (let key in data) {
            for (let sub_key in data[key]["subtasks"]) {
                if (data[key]["subtasks"][sub_key]["status"] === 2) {
                    _finished += 1;
                } else if (data[key]["subtasks"][sub_key]["status"] === 1) {
                    _processing += 1;
                } else if (data[key]["subtasks"][sub_key]["status"] === 0) {
                    _waiting += 1;
                } else if (data[key]["subtasks"][sub_key]["status"] === 3) {
                    _failed += 1;
                }
            }
        }
        setFinished(_finished);
        setProcessing(_processing);
        setWaiting(_waiting);
        setFailed(_failed);
    }, [data]);

    React.useEffect(() => {
        const message = new FormData();
        fetch(`${process.env.REACT_APP_API_URL}/api/get_remain_resources`, {
            method: 'POST',
            headers: {
                'Authorization': localStorage.getItem('token'),
            },
            body: message,
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Network response was not ok.');
            })
            .then(data => {
                setResidue(data["remain_task_limit"]);
                setUsed(data["disk_usage"]);
                setUpdateTime(data["update_time"])
                setStorage(data["disk_total"]);
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
            });

    }, [data]);

    return (
        <Box
            sx={{
                width: '100%',
                backgroundColor: '#FAFAFA',
                padding: window.innerWidth < 800 ? '0 15px' : '0px 80px',
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <Box sx={{ width: window.innerWidth > 1500 ? '1450px' : '100%' }}>
                <Box
                    sx={{
                        fontFamily: 'Inter',
                        fontSize: '23.63px',
                        fontWeight: '500',
                        lineHeight: '30.63px',
                        padding: '20px 0 10px 0',
                    }}
                >
                    Welcome, <span style={{ color: '#517DFF' }}>{localStorage.getItem('username')}</span>
                </Box>
                <Box sx={{
                    fontSize: '16px',
                    fontWeight: '500',
                    color: '#9C9C9C',
                    fontFamily: 'Inter',
                    textAlign: 'left',
                    lineHeight: '25.35px',
                    padding: '0 0 25px 0',
                }}>
                    Today is {new Date().toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}
                </Box>
                <Divider />
                <Box component={'div'} sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    with: '100%'
                }}>
                    {jobStatus('finished', finished)}
                    {jobStatus('processing', processing)}
                    {jobStatus('failed', failed)}
                    {jobStatus('pending',
                        waiting
                    )}

                </Box>

            </Box>
        </Box>
    );
}

export default TitleInfo;