import React from 'react';
import AF from './img/AF.jpg';
import AFM from './img/AFM.jpg';
import ESM from './img/ESM.jpg';
import LIGANDMPNN from './img/LigandMPNN.jpg';
import RFAA from './img/RFAA.png';
import STFold from './img/STFold.jpeg';
import { Box } from '@mui/material';

const Tag = ({ text }) => (
    <Box
        sx={{
            backgroundColor: 'rgba(81, 125, 255, 0.16)',
            color: 'rgba(70, 77, 209, 1)',
            fontSize: '12px',
            fontWeight: '600',
            padding: '2px 8px',
            borderRadius: '10px',
            marginRight: '8px',
        }}
    >
        {text}
    </Box>
);

// 提取模型信息的映射
const modelData = {
    AF: {
        name: 'AlphaFold2 (Single)',
        description: 'A fast version of AlphaFold2 for single protein structure prediction without using MSA database.',
        img: AF,
        tags: ['Protein Prediction']
    },
    AFM: {
        name: 'AlphaFold2 (Multimer)',
        description: 'Accurately predict protein structure and even protein-protein complex with MSA database.',
        img: AFM,
        tags: ['Protein Prediction', 'High Quality']
    },
    ESM: {
        name: 'ESMFold',
        description: 'Protein language model-based 3D structure prediction at the atomic level.',
        img: ESM,
        tags: ['Protein Prediction', 'Protein Language Model']
    },
    LigandMPNN: {
        name: 'LigandMPNN',
        description: 'Deep learning-based protein sequence design method that allows explicit modeling of small molecule, nucleotide, metal, and other atomic contexts.',
        img: LIGANDMPNN,
        tags: ['Sequence Design']
    },
    STFold: {
        name: 'ShanghaiTech Fold ',
        description: 'A Diffusion-based Foundation Model for All-atom Biomolecular Assembly.',
        img: STFold,
        tags: ['All-atom Structure Assembly', 'Ligand binding']
    },
    default: {
        name: '',
        description: '',
        img: RFAA,
        tags: []
    }
};

export default function Card({ type, choose }) {
    // 获取对应模型的数据，如果没有找到则使用 default
    const { name, description, img, tags } = modelData[type] || modelData.default;

    return (
        <Box
            sx={{
                borderRadius: '10px',
                margin: '0 20px',
                cursor: 'pointer',
                width: '300px',
            }}
        >
            {/* 图像 */}
            <Box
                sx={{
                    border: type === choose ? '8.96px solid #517DFF' : '',
                    width: '300px',
                    height: '300px',
                    borderRadius: '10px',
                    overflow: 'hidden',
                }}
            >
                <img
                    src={img}
                    alt={type}
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                    }}
                />
            </Box>

            {/* 模型名称 */}
            <Box
                sx={{
                    marginTop: '16px',
                    fontSize: '20.37px',
                    fontWeight: '600',
                    color: '#000000',
                    textAlign: 'left'
                }}
            >
                {name}
            </Box>

            {/* 标签 */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    marginTop: '16px',
                    whiteSpace: 'nowrap'
                }}
            >
                {tags.map((tag, index) => (
                    <Tag key={index} text={tag} />
                ))}
            </Box>

            {/* 模型描述 */}
            <Box
                sx={{
                    marginTop: '8px',
                    fontSize: '16.3px',
                    fontWeight: '400',
                    color: '#919191',
                    textAlign: 'left'
                }}
            >
                {description}
            </Box>
        </Box>
    );
}
