import React, { useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from './Card';
import Button from '@mui/material/Button';
import UploadPage from './UploadPage';

export default function Home() {
    const [taskList, setTaskList] = useState([]);
    const [messageOpen, setMessageOpen] = useState(false);
    const [messageType, setMessageType] = useState('success');
    const [countSuccess, setCountSuccess] = useState(0);
    const [countFail, setCountFail] = useState(0);
    const [choose, setChoose] = useState('AFM');
    const [uploadComponent, setUploadComponent] = useState(null);

    useEffect(() => {
        if (localStorage.getItem('token')) {
            updateList();
        }
    }, []);

    const updateList = () => {
        const formData = new FormData();
        fetch(`${process.env.REACT_APP_API_URL}/api/get_task_names`, {
            method: 'POST',
            body: formData,
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        })
            .then(response => {
                if (response.status === 401) {
                    localStorage.clear();
                    window.location.href = '/login';
                }
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => setTaskList(data.task_names))
            .catch(error => {
                console.error('There was a problem with your fetch operation:', error);
            });
    };

    const handleTaskCreate = () => {
        if (!localStorage.getItem('token')) {
            window.location.href = '/login';
            return;
        }
        setUploadComponent(true);
    };

    const handleTaskCreateClose = () => setUploadComponent(false);

    const handleMessageClose = (event, reason) => {
        if (reason !== 'clickaway') {
            setMessageOpen(false);
        }
    };

    return (
        <Box sx={{
            minHeight: 'calc(100vh - 64px)',
            position: 'relative',
            display: 'block',
            width: '100vw',
            transition: 'all 0.3s',
            backgroundColor: '#f3f5f7',
            backgroundSize: 'cover',
            zIndex: '-2',
        }}>
            {!uploadComponent &&
                <Box>
                    <Box sx={{
                        fontWeight: 'bold',
                        padding: '10vh 0',
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-around',
                    }}>
                        <Box sx={{ textAlign: 'left' }}>
                            <Box sx={{ color: '#919191', fontSize: '27.3px', fontWeight: '500' }}>
                                ShanghaiTech University
                            </Box>
                            <Box sx={{ color: '#000000', fontSize: '46.82px', fontWeight: '800', margin: '10px 0' }}>
                                AI Protein Prediction Platform
                            </Box>
                            <Box sx={{ color: '#464DD1', fontSize: '28px', fontWeight: '500' }}>
                                Begin with choosing a model
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                color: '#464DD1',
                                fontSize: '23.63px',
                                fontWeight: '500',
                                cursor: 'pointer'
                            }}
                            onMouseEnter={(e) => { e.target.style.textDecoration = 'underline' }}
                            onMouseLeave={(e) => { e.target.style.textDecoration = 'none' }}
                            onClick={() => { window.location.href = '/docs'; }}>
                            Learn more {`>>`}
                        </Box>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            maxWidth: '1600px',
                        }}>
                            {['AFM', 'ESM', 'STFold', 'LigandMPNN'].map((modelType) => (
                                <Box key={modelType} onClick={() => setChoose(modelType)}>
                                    <Card type={modelType} choose={choose} />
                                </Box>
                            ))}
                        </Box>
                        <Button
                            variant="outlined"
                            size="large"
                            sx={{ margin: '20px 0', color: '#517DFF' }}
                            onClick={handleTaskCreate}
                        >
                            Create New Project
                        </Button>
                    </Box>
                </Box>
            }
            {uploadComponent &&
                <UploadPage
                    task_list={taskList}
                    updateTaskList={updateList}
                    cardType={choose}
                    close={handleTaskCreateClose}
                    setCountSuccess={setCountSuccess}
                    setCountFail={setCountFail}
                    setMessageType={setMessageType}
                    setMessageOpen={setMessageOpen}
                />
            }
            <Snackbar
                open={messageOpen}
                autoHideDuration={6000}
                onClose={handleMessageClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert
                    onClose={handleMessageClose}
                    severity={messageType}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {
                        countSuccess === 0 && countFail === 0 ? 'No task created!' :
                            countSuccess === 0 ? 'All tasks failed to create!' :
                                countFail === 0 ? 'All tasks created successfully!' :
                                    `${countSuccess} Task(s) created successfully! ${countFail} Task(s) failed to create!`
                    }
                </Alert>
            </Snackbar>
        </Box>
    );
}
