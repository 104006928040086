import React, { Component } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
import PDBViewer from './PDBViewer';
// import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Backdrop from '@mui/material/Backdrop';
import LinearProgress from '@mui/material/LinearProgress';
import TitleInfo from './TitleInfo';
import Row from './TaskRow';
import TaskReport from './TaskReport';
import { TaskCard } from '../Home/CardContainer';
// import SwapVertIcon from '@mui/icons-material/SwapVert';
import Divider from '@mui/material/Divider';

// eslint-disable-next-line
function DataFilter({ setSortTaskType, setSearchContent }) {
    const [type, setType] = React.useState('date');
    const [search, setSearch] = React.useState('');

    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearch(value);
        setSearchContent(value);
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: window.innerWidth < 800 ? 'wrap' : 'nowrap', width: window.innerWidth > 1500 ? '1450px' : '100%' }}>
            <Box sx={{ fontWeight: '600', fontSize: '20.37px', fontFamily: 'Inter', paddingLeft: '40px' }}>
                My Tasks
                <Box
                    component="form"
                    sx={{
                        p: '2px 4px', alignItems: 'center', width: '300px',
                        borderRadius: '29.801px',
                        background: 'rgba(252, 252, 252, 0.50)',
                        margin: '20px 10px',
                        marginLeft: '50px',
                        display: 'inline-block',
                        backgroundColor: 'rgba(217, 217, 217, 0.3)'
                    }}
                >
                    <IconButton type="button" sx={{ p: '10px', color: '#517DFF' }} aria-label="search">
                        <SearchIcon />
                    </IconButton>
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Search for ..."
                        value={search}
                        onChange={handleSearchChange}
                    />
                </Box>
            </Box>
            <Box>
                <Box sx={{
                    margin: '20px 0',
                    display: 'inline-block'
                }}>
                    <Box component='span' sx={{ color: '#A1A1A1', fontSize: '16px', fontWeight: '500', fontFamily: 'Inter' }}>
                        Show Entries in Order of
                    </Box>
                    <Select
                        value={type}
                        onChange={(event) => {
                            setType(event.target.value);
                            setSortTaskType(event.target.value);
                        }}
                        sx={{
                            ml: 1,
                            color: '#517DFF',
                            background: 'rgba(231, 231, 231, 0.69)',
                            borderRadius: '10px',
                            width: '200px',
                            border: '0px !important',
                            '.css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                                border: '0px !important'
                            }
                        }}
                        size='small'
                    >
                        <MenuItem value={'task_name'}>Task name</MenuItem>
                        <MenuItem value={'model'}>Model</MenuItem>
                        <MenuItem value={'length'}>Length</MenuItem>
                        <MenuItem value={'date'}>Created Date</MenuItem>
                    </Select>
                </Box>
            </Box>
        </Box>
    );
}



function CollapsibleTable() {
    const [data, setData] = React.useState({});
    const [unsortedData, setUnsortedData] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const [progress, setProgress] = React.useState(0);
    const [reportOpen, setReportOpen] = React.useState(false);
    const [reportData, setReportData] = React.useState({});
    const [ligandNextOpen, setLigandNextOpen] = React.useState(false);
    const [lifileName, setLiFileName] = React.useState('');
    const [lifileId, setLiFileId] = React.useState('');
    const [taskList, setTaskList] = React.useState([])
    const [sortTaskType, setSortTaskType] = React.useState('date');
    const [searchContent, setSearchContent] = React.useState('');

    React.useState(() => {
        get_tasks();
        update_list();
        const interval = setInterval(() => {
            get_tasks();
        }, 10000);
        return () => clearInterval(interval);
    }, []);

    React.useEffect(() => {
        if (data) {
            setData(sort_task(search_task(unsortedData)));
        }
        // eslint-disable-next-line
    }, [sortTaskType, searchContent]);

    React.useEffect(() => {
        if (unsortedData) {
            setData(sort_task(search_task(unsortedData)));
        }
        // eslint-disable-next-line
    }, [unsortedData]);

    function search_task(unsortedData) {
        if (!searchContent) return unsortedData;

        return unsortedData.filter(task =>
            Object.values(task.subtasks).some(subtask =>
                subtask.file_name.toLowerCase().includes(searchContent.toLowerCase())
            ) ||
            task.task_name.toLowerCase().includes(searchContent.toLowerCase()) ||
            task.type.toLowerCase().includes(searchContent.toLowerCase()) ||
            task.sequence_length.toString().includes(searchContent)
        );
    }

    function update_list() {
        const formData = new FormData();
        fetch(`${process.env.REACT_APP_API_URL}/api/get_task_names`, {
            method: 'POST',
            body: formData,
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }).then(response => {
            if (response.status === 401) {
                localStorage.clear();
                window.location.href = '/login';
            }
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
            .then(data => {
                setTaskList(data.task_names);
            })
            .catch(error => {
                // 在这里处理错误
                console.error('There was a problem with your fetch operation:', error);
            });
    }


    async function get_tasks() {
        const message = new FormData();
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/get_tasks`, {
                method: 'POST',
                body: message,
                headers: {
                    'Authorization': localStorage.getItem('token'),
                }
            });
            const data_return = await response.json();
            if (response.status === 200) {
                // init sort data by start time
                let tmp_data = [];
                Object.keys(data_return["index_infos"]).forEach(key => {
                    let tmp_row_data = data_return["index_infos"][key];
                    let sequence_length = 0;
                    Object.keys(tmp_row_data['subtasks']).forEach(key => {
                        sequence_length += tmp_row_data['subtasks'][key]['sequence_length'];
                    });
                    tmp_row_data['task_name'] = key;
                    tmp_row_data['sequence_length'] = sequence_length;
                    tmp_data.push(tmp_row_data);
                });
                setUnsortedData(tmp_data);
            } else if (response.status === 401) {
                localStorage.clear();
                window.location.href = '/login';
            } else {
                console.log(data.message);
            }
        } catch (error) {
            console.log(error);
        }
    }

    function sort_task(data) {
        let new_data = [];
        let sorted_keys = [];
        if (sortTaskType === 'task_name') {
            sorted_keys = Object.keys(data).sort((a, b) => data[a]['task_name'].localeCompare(data[b]['task_name']));
        } else if (sortTaskType === 'model') {
            sorted_keys = Object.keys(data).sort((a, b) => data[a]['type'].localeCompare(data[b]['type']));
        } else if (sortTaskType === 'length') {
            sorted_keys = Object.keys(data).sort((a, b) => data[b]['sequence_length'] - data[a]['sequence_length']);
        } else if (sortTaskType === 'date') {
            sorted_keys = Object.keys(data).sort((a, b) => new Date(data[b].start_time) - new Date(data[a].start_time));
        } else {
            sorted_keys = Object.keys(data);
        }
        sorted_keys.forEach(key => {
            new_data.push(data[key]);
        });
        // console.log(new_data)
        return new_data;
    }

    function row_data_sort(row) {
        const keys = Object.keys(row.subtasks).sort((a, b) => new Date(row.subtasks[b].create_time) - new Date(row.subtasks[a].create_time));
        // console.log(keys);
        const new_row = {};
        keys.forEach(key => {
            new_row[key] = row.subtasks[key];
        });
        row.subtasks = new_row;
        return row;
    }

    return (
        <Box component={'div'}>
            <Box>
                <TitleInfo data={data} />
                <Box
                    sx={{
                        padding: window.innerWidth < 800 ? '0' : '0px 80px',
                        width: '100%',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Box sx={{ border: 'none' }}>
                            {
                                window.innerWidth < 800 ? null : <DataFilter setSortTaskType={setSortTaskType} setSearchContent={setSearchContent} />
                            }
                            <Divider />
                            {Object.keys(data)
                                // .sort((a, b) => new Date(data[b].start_time) - new Date(data[a].start_time))
                                .map((key) => (
                                    <Row
                                        key={data[key]['task_name']}
                                        row={row_data_sort(data[key])}
                                        task_name={data[key]['task_name']}
                                        setLoading={setLoading}
                                        setProgress={setProgress}
                                        get_tasks={get_tasks}
                                        setReportData={setReportData}
                                        setReportOpen={setReportOpen}
                                        setLigandNextOpen={setLigandNextOpen}
                                        setLiFileName={setLiFileName}
                                        setLiFileId={setLiFileId}
                                    />
                                ))}
                        </Box>
                    </Box>


                </Box>
            </Box>


            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', height: '100%' }}
                open={loading}
            >
                {progress < 0.01 ? <span style={{ fontSize: '24px', margin: '20px' }}>Packing...</span>
                    :
                    <>
                        <span style={{ fontSize: '24px', margin: '20px' }}>Downloading...</span>
                        <LinearProgress variant="determinate" value={progress} sx={{ width: '80%', height: '10px', borderRadius: '5px' }} />
                        <span style={{ fontSize: '24px', margin: '20px' }}>{progress.toFixed(1)}%</span>
                    </>
                }
            </Backdrop>
            <Backdrop
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', height: '100%' }}
                open={reportOpen}
                onClick={() => { setReportOpen(false) }}
            >
                <TaskReport
                    setReportOpen={setReportOpen}
                    reportData={reportData}
                    reportOpen={reportOpen}
                />
            </Backdrop>
            <Backdrop
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', height: '100%' }}
                open={ligandNextOpen}
                onClick={() => { setLigandNextOpen(false) }}
            >
                <TaskCard
                    liopen={ligandNextOpen}
                    setLigandNextOpen={setLigandNextOpen}
                    lifileName={lifileName}
                    lifileId={lifileId}
                    task_list={taskList}
                    updateTaskList={update_list}
                />
            </Backdrop>
        </Box>
    );
}

export default class Task extends Component {
    state = {
        url: null,
        search: window.location.search,
    }

    componentDidMount() {
        if (!localStorage.getItem('username')) {
            window.location.href = '/login';
        }

    }


    render() {
        const gridStyle = {
            height: 'calc(100vh - 64px)',
            position: 'relative',
            display: 'block',
            width: '100vw',
            transition: 'all 0.3s',
            background: '#ffffff',
            overflow: 'scroll',
        };
        return (
            <Box component={'div'} style={gridStyle}>
                {this.state.search ? (
                    <PDBViewer />
                ) : (
                    <Box component={'div'}>
                        <CollapsibleTable />
                    </Box>
                )}
            </Box>
        );
    }
}
